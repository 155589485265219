import { gql } from "@apollo/client";


// Definir la consulta GraphQL para listar las licitaciones
export const LISTAR_LICITACIONES = gql`
  query ListarLicitaciones($pagina: Int, $limite: Int, $anio: Int) {
    listarLicitaciones(pagina: $pagina, limite: $limite, anio: $anio) {
      id
      nombre
      resumen
      vigencia
      juntaAclaraciones
      propuestas
      falloAdjudicacion
      archivo
      publicada
    }
  }
`;
