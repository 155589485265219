import React from "react";
import { Card } from "react-bootstrap";
import { formatFecha } from "../../../utils/formatFecha";
const LicitacionCard = ({ licitacion }) => {
  return (
    <Card className="shadow-lg h-100">
      <Card.Header className=" text-white px-3 py-2">
        <h4 className="card-title mb-0">{licitacion.nombre}</h4>
        <h5>{licitacion.resumen}</h5>
      </Card.Header>
      <Card.Body>
        <p>
          <strong>Periodo de Vigencia:</strong>
          <br />
          {licitacion.vigencia}
        </p>
        <hr />
        <p>
          <strong>Junta de Aclaraciones:</strong>
          <br />
          {formatFecha(licitacion.juntaAclaraciones)}
        </p>
        <hr />
        <p>
          <strong>Presentación y Apertura:</strong>
          <br />
           {formatFecha(licitacion.propuestas)}
        </p>
        <hr />
        <p>
          <strong>Fallo de Adjudicación:</strong>
          <br />
          {formatFecha(licitacion.falloAdjudicacion)}
        </p>
      </Card.Body>
      <Card.Footer className="text-muted">
        <small>Descarga y Consultar licitación {licitacion.updated_at}</small>
      </Card.Footer>
    </Card>
  );
};

export default LicitacionCard;
