import React from "react";
import SectionHeading from "../Common/SectionHeading";
import { ConvocatoriaData } from "../Common/Convocatorias/Convocatoria";
import BlogCard from "../Common/Blog/BlogCard";
const Convocatorias = () => {
  return (
    <>
      <section id="news_blog_area">
        <div className="container">
          <SectionHeading
            heading="Convocatorias"
            para="Consulte las convocatorias para ocupar cargo público"
          />
          <div className="row">
            {ConvocatoriaData.slice(0, 3).map((data, index) => (
              <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={index}>
                <BlogCard
                  img={data.imagen}
                  heading={data.titulo}
                  para={data.resumen}
                  date={data.fecha}
                  location={data.url}
                  link={data.url}
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default Convocatorias;
