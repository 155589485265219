import React from "react";
import CommonBanner from "../component/Common/Banner";
import ContraloriaDetalles from "../component/Common/Contraloria/ContraloriaDetalles";
import Declaranet from "../component/Common/Contraloria/Declaranet";
import ContraloriaSidebar from "../component/Common/Contraloria/ContraloriaSidebar";
const Contraloria = () => {
  return (
    <div>
      <CommonBanner heading="Contraloria Municipal" page="Contraloria Municipal" />
      <section id="service_details_area">
        <div className="container">
            <div className="row">
                <ContraloriaDetalles/>
                <ContraloriaSidebar/>
                <Declaranet/>
            </div>
        </div>
      </section>
    </div>
  );
};

export default Contraloria;
