import React from 'react'
import {Link} from 'react-router-dom';
// Import Img
import img1 from '../../../assets/img/common/about-service.jpg'

const HomeTwoAbout = () => {
    return (
        <>
            <section id="service_about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="about_service_img">
                                {/* <img src={img1} alt="img-about"/> */}
                            </div>
                        </div>
                        <div className="col-lg-6  col-md-12 col-sm-12 col-12">
                            <div className="about_service_text">
                                <div className="heading-left-border">
                                    <h2>Estamos realizando tareas de mejoras</h2>
                                </div>
                                <p>Existe la posibilidad de que algunas secciones aún no estén habilitadas, estamos trabajando en ello.</p>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeTwoAbout
