// utils/fechaUtils.js

export const formatFecha = (fechaISO) => {
    const fecha = new Date(fechaISO);
  
    // Formatea la parte de la fecha
    const opcionesFecha = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    const fechaFormateada = new Intl.DateTimeFormat('es-ES', opcionesFecha).format(fecha);
  
    // Formatea la parte de la hora
    const opcionesHora = {
      hour: '2-digit',
      minute: '2-digit',
    };
    const horaFormateada = fecha.toLocaleTimeString('es-ES', opcionesHora);
  
    return `${fechaFormateada}, ${horaFormateada} horas`;
  };
  