import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const ContraloriaDetalles = () => {
  return (
    <div className="col-lg-8">
      <div className="service_details_items">
        <div className="service_details_para">
          <h3>Protesta Ciudana</h3>
          <p>
            Conforme a derecho y con fundamento en los artículos 1, 2, 3, 4, 91
            y 93 de la Ley General de Responsabilidades Administrativas, y todos
            los demás artículos y códigos aplicables, se utilizará el siguiente
            formato para presentar queja, denuncia y/o sugerencia de alguna
            inconformidad relacionada por los trámites o servicios otorgados por
            los servidores públicos que laboran en el H. Ayuntamiento de Lázaro
            Cárdenas, Michoacán.
          </p>
          <div id="accordion_contraloria" className="accordion-wrapper">
            <div id="accordion_protesta" key={0}>
              <div className="card box-shadow">
                <div className="card-header">
                  <h5 className="mb-0">
                    <a
                      href="#!"
                      className="collapsed "
                      role="button"
                      data-toggle="collapse"
                      data-target={"#collapse" + 0}
                      aria-expanded="false"
                      aria-controls={"collapse" + 0}
                    >
                      Iniciar protesta en línea
                    </a>
                  </h5>
                </div>
                <div
                  id={"collapse" + 0}
                  className={"0" === 0 ? "collapse show active" : "collapse"}
                  aria-labelledby={"heading" + 0}
                  data-parent={"#accordion"}
                >
                  <div className="card-body active">
                    <p>
                      Puedes iniciar tu protesta en línea haciendo clic en este
                      enlace:
                      <Link
                        to={{
                          pathname:
                            "https://mejora.lazaro-cardenas.gob.mx/protesta-ciudadana",
                        }}
                        target="_blank"
                      >
                        Protesta Ciudadana
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="accordion_formato" key={1}>
              <div className="card box-shadow">
                <div className="card-header">
                  <h5 className="mb-0">
                    <a
                      href="#!"
                      className="collapsed "
                      role="button"
                      data-toggle="collapse"
                      data-target={"#collapse" + 1}
                      aria-expanded="false"
                      aria-controls={"collapse" + 1}
                    >
                      Descargar formato de Quejas, Denuncias y Sugerencias
                    </a>
                  </h5>
                </div>
                <div
                  id={"collapse" + 1}
                  className={"1" === 1 ? "collapse show active" : "collapse"}
                  aria-labelledby={"heading" + 1}
                  data-parent={"#accordion"}
                >
                  <div className="card-body active">
                    <p>
                      Si lo prefiere, puede descargar el formato de quejas,
                      denuncias y sugerencias y presentarlo en nuestro buzón de
                      quejas y sugerencias en Contraloría Municipal:
                      <Link
                        to={{
                          pathname:
                            "https://mail.lazaro-cardenas.gob.mx/Contraloria/Formato_de_quejas_denuncias_y_sugerencias.pdf",
                        }}
                        target="_blank"
                      >
                        Descargar el formato
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div id="accordion_formato" key={2}>
              <div className="card box-shadow">
                <div className="card-header">
                  <h5 className="mb-0">
                    <a
                      href="#!"
                      className="collapsed "
                      role="button"
                      data-toggle="collapse"
                      data-target={"#collapse" + 2}
                      aria-expanded="false"
                      aria-controls={"collapse" + 2}
                    >
                      Denunciar vía telefónica
                    </a>
                  </h5>
                </div>
                <div
                  id={"collapse" + 2}
                  className={"2" === 2 ? "collapse show active" : "collapse"}
                  aria-labelledby={"heading" + 2}
                  data-parent={"#accordion"}
                >
                  <div className="card-body active">
                    <p>
                    Si lo prefiere puede presentar su queja, denuncia y/o sugerencia vía telefónica al número:
                    (753) 540 33 00 Ext. 121
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContraloriaDetalles;
