import React from 'react'
import HomeBanner from '../component/Home_One/Banner'
import HomeTwoAbout from '../component/Home_Two/About'
import Convocatorias from '../component/Convocatorias/Convocatorias'

const index = () => {
    return (
        <>
            <HomeBanner/>
            <Convocatorias/>
            <HomeTwoAbout/>
            {/* <HomeAbout/> */}
            {/* <GetSchedule/>
            <LogisticsService/>
            
            <OurAdvantage/>
            <MapArea/>
            <Testimonials/>
            <PricingTable/>
            <Subscribe/>
            <BlogHome/> */}
        </>
    )
}

export default index
