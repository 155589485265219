import React from "react";

const ContraloriaSidebar = () => {
  return (
    <>
      <div className="col-lg-4">
        <div className="service_details_sidebar">
          <div className="sidebar_service_wrappers">
            <h3>Contacto</h3>
            <div className="contact_sidebar">
              <h6>Oficina</h6>
              <p>Av. Lázaro Cárdenas No. 516 Col. Centro. Segunda Planta</p>
            </div>
            <div className="contact_sidebar">
              <h6>Teléfono</h6>
              <p>Oficina: (753) 540 33 00 Ext. 121</p>
              
            </div>
            <div className="contact_sidebar">
              <h6>Correo electrónico</h6>
              <p>contraloria@lazaro-cardenas.gob.mx</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContraloriaSidebar;
