import React from "react";
import { Link } from "react-router-dom";
import imageDeclaranet from "../../../assets/img/common/DECLARANET.png";
const Declaranet = () => {
  return (
    <>
      <section id="service_about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-12 col-sm-12 col-12">
              <div className="about_service_img">
                <img src={imageDeclaranet} alt="img-about" />
              </div>
            </div>
            <div className="col-lg-6  col-md-12 col-sm-12 col-12">
              <div className="about_service_text">
                <div className="heading-left-border">
                  <h2>Realiza tu declaración patrimonial</h2>
                </div>
                <p>
                  El sistema DeclaraNet, es la herramienta electrónica que
                  administra la Contraloria Municipal, a través de la cual las
                  personas servidoras públicas municipales presentan sus
                  declaraciones de situación patrimonial y de intereses, ello en
                  cumplimiento de lo establecido en los artículos 108, último
                  párrafo de la Constitución, 104, último párrafo de la
                  Constitución Política del Estado Libre y Soberando de
                  Michoacán de Ocampo, así como en los diversos 32, 33, primer
                  párrafo, y 35, segundo párrafo, de la Ley de Responsabilidades
                  Administrativas para el Estado de Michoacán de Ocampo
                </p>

                <Link
                  to={{ pathname: "http://54.39.22.0:5000/" }}
                  target="_new"
                  className="btn btn-theme"
                >
                  Acceder al sistema
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Declaranet;
