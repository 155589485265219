import { ApolloClient, InMemoryCache } from '@apollo/client';
import { createUploadLink } from 'apollo-upload-client';
import { setContext } from '@apollo/client/link/context';

// Configurar encabezados de autenticación
const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token');
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

// Configurar enlace para subidas de archivos
const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_URL_GRAPH_QL // Cambia al endpoint de tu servidor
});

// Configuración del cliente Apollo
const client = new ApolloClient({
  link: authLink.concat(uploadLink),
  cache: new InMemoryCache()
});

export default client;
