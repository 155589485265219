import React, { useState } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { useQuery } from "@apollo/client";
import { LISTAR_LICITACIONES } from "../../../../gql/licitaciones";
import LoadingWithText from "../../../Customs/LoadingMlc/LoadingWithText";
import LicitacionCard from "../../../Customs/LoadingMlc/LicitacionCard";
const LicitacionesDetalle = () => {
  const [pagina, setPagina] = useState(1); // Página actual
  const limite = 5; // Elementos por página
  const { loading, error, data } = useQuery(LISTAR_LICITACIONES, {
    variables: { pagina, limite },
  });
  if (error) {
    return <h1>error</h1>;
  }

  if (loading) {
    return <LoadingWithText />;
  }

  // Datos obtenidos de la consulta
  const { listarLicitaciones } = data;

  return (
    <div className="col-lg-12">
      <div className="service_details_items">
        {listarLicitaciones.length > 0 ? (
          listarLicitaciones.map((licitacion, rowIndex) => (
            <Row key={rowIndex} className="mb-4 gx-3">
              <Col key={licitacion.id} xs={12} sm={6} md={12} className="mb-4">
                <LicitacionCard licitacion={licitacion} />
              </Col>
            </Row>
          ))
        ) : (
          <p>No hay más licitaciones disponibles.</p>
        )}
        {/* Controles de Paginación */}
        {/* Controles de Paginación */}
        <div className="d-flex justify-content-between align-items-center mt-4">
          <Button
            disabled={pagina === 1}
            onClick={() => setPagina((prev) => prev - 1)}
          >
            Anterior
          </Button>
          <span>Página {pagina}</span>
          <Button
            disabled={listarLicitaciones.length === 0}
            onClick={() => setPagina((prev) => prev + 1)}
          >
            Siguiente
          </Button>
        </div>
      </div>
    </div>
  );
};
export default LicitacionesDetalle;
