import React from "react";
import { Spinner } from "react-bootstrap";

const LoadingWithText = () => {
  return (
    <div className="col-lg-12">
      <div className="service_details_items">
        <div
          className="d-flex flex-column justify-content-center align-items-center"
          style={{ height: "100vh" }}
        >
          <Spinner animation="border" role="status" variant="warning">
            <span className="visually-hidden">
              <h4>Cargando...</h4>
            </span>
          </Spinner>
          <p className="mt-3">
            <h5>Renderizando contenido, por favor espera...</h5>
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoadingWithText;
