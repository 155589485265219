import { Col, Row } from "react-bootstrap";
import CommonBanner from "../../component/Common/Banner";
import LicitacionesDetalle from "../../component/Common/Compras/Licitaciones/LicitacionesDetalle";

const Licitaciones = () => {
  return (
    <div>
      <CommonBanner heading="Licitaciones" page="Compras | Licitaciones" />
      <section id="service_details_area">
        <div className="container">
        <h3>Licitaciones</h3>
        <p>Consulta en este espacio las licitaciones públicas vigentes</p>
          <Row>
            <Col xs={12} sm={6} md={8} className="mb-4">
              <LicitacionesDetalle />
            </Col>
          </Row>
        </div>
      </section>
    </div>
  );
};

export default Licitaciones;
